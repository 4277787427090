.interactive-box-container{
    position: fixed;
    top: 2%; 
    left: 2%; 
    width: 96%; 
    z-index: 10;
    border-radius: 20px;
    padding: 5%;
}

.progressbar {
    width: 100%;
    background-color:#1FAA00;
    border: 1px solid white;
    opacity:0.5;
    height: 10px;
}

.interactive-box-text{
    font-weight: 600;
}

.inner {
    height: 9px;
    background-color:#FFF;
    animation: progressbar-countdown;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

@keyframes progressbar-countdown {
    0% { width: 0%; }
    100% { width: 100%; }
}