.popup-inner .close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
}


.white-title{
    color:#FFF;
    font-size:16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.alertDiv-title{
    padding: 10px;
    border-radius: 15px 15px 0 0;
    background-color: var(--blue);
}

.alertDiv-content{
    padding: 20px;
    border-radius:  0 0 15px 15px;
    background-color: var(--background);
    font-size: 14px;
    font-weight: normal
}

.featuresDiv-popup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    flex-wrap: wrap;
    padding: 10px;
}