.search-bar{
    width: 100%;
    margin-right: 1em;
    min-width: 180px;
    outline: none;
    background-color: white;
    border-radius: 20px;
    border-style: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    position: relative;
}

.search-bar:focus-within{
    border: 2px solid #1FA901;
    background-color: white;
}

.input-destination {
    outline: none;
    border-style: hidden;
    width: 100%;
    background-color: white;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
    background: transparent;
}

.favorite-recent-addresses{
    width: 100%;
    background-color: white;
    border-radius: 20px;
    z-index: 5;
}

.deactivated-search-bar{
    color: #1faa00;
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 12px;
}