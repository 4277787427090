.input-form{
    outline: none;
    border-style: hidden;
    width: 90%;
    background-color: white;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
    background: transparent;
    border-bottom: 1px solid var(--cocoparksGreen);
}

.error-message{
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: var(--filling-red);
    text-align: center;
    margin-top: 15px;
}

.validation-message{
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    color: var(--cocoparksGreen);
    text-align: center;
}