.details-panel{
    min-height: 20vh;
    width: 100%;
    border: solid var(--cocoparksGreen) 5px;
    background-color: white;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
    position: relative;

}
  
.container-expanded {
    top: 0px;
    width: 100%;
}

.expanded-panel-enter {
    top: 0px;
    width: 100%;
    max-height: 0px;
}

.expanded-panel-enter-active {
    top: 0px;
    width: 100%;
    max-height: 300px;
    transition: all 800ms;
}

.expanded-panel-exit {
    top: 0px;
    width: 100%;
    max-height: 300px;
}

.expanded-panel-exit-active {
    top: 0px;
    width: 100%;
    max-height: 0px;
    transition: all 800ms;
}

.container-original {
    top: 0px;
    width: 100%;
    margin-bottom: 10px;
}

.original-panel-enter {
    opacity: 0;
    top: 0px;
    width: 100%;
    max-height: 0px;
}

.original-panel-enter-active {
    opacity: 1;
    top: 0px;
    width: 100%;
    max-height: 300px;
    transition: all 250ms;
}

.original-panel-exit {
    opacity: 1;
    top: 0px;
    width: 100%;
    max-height: 300px;
}

.original-panel-exit-active {
    opacity: 0;
    top: 0px;
    width: 100%;
    max-height: 0px;
    transition: all 250ms;
}

.torch-button-enter {
    margin-left: -100%;
}

.torch-button-enter-active {
    margin-left: 0%;
    transition: all 500ms;
}

.torch-button-exit {
    margin-left: 0%;
}

.torch-button-exit-active {
    margin-left: -100%;
    transition: all 500ms;
}

.right-button-enter {
    margin-right: -100%;
}

.right-button-enter-active {
    margin-right: 0%;
    transition: all 500ms;
}

.right-button-exit {
    margin-right: 0%;
}

.right-button-exit-active {
    margin-right: -100%;
    transition: all 500ms;
}