.screenshot{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: solid #1faa00;
    border-radius: 25px;
    background-color: white;
    height: 100%;
    width: 100%;
    padding: 15px;
    overflow: hidden;
}

.close-screenshot-button{
    z-index: 1;
    background: #1faa00;
    border: solid white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    right: -20px;
    top: -20px;
}

video {
    height: 100% !important;
    width: 80vw;
    object-fit: cover;
    object-position: center;
}

.canvas-stream{
    display: none;
}

.screenshot-button {
    background-color: var(--cocoparksGreen); /* #1faa00 */
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    margin: auto;
    cursor: pointer;
    border-radius: 15px;
    width: 150px;
    height: 40px;
    margin: 0
}

.screenshot-img{
    z-index: 1001;
    border: solid red;
    border-width: thin;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin-top: 15px;
    background-size: cover;
    background-position: top center;
    object-fit: cover;
}

.screenshot-img img{
    width: 100%;
}

.screenshot-img-displayed{
    width: 100% !important;
    margin: 15px 0px 15px 0px;
    object-fit: cover;
}


.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 1;
	}
    50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.video-stream{
    max-height: 40vh !important;
    width: auto;
}