.cocoLogo{
    width: 40px; 
    height: 40px
}

.labelText{
    font-weight: bold;
    font-size: 15px;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0px;
}