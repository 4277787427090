.button-filter-icon {
    font-size: 25px !important;
}


@media screen and (max-width : 320px)
{
    .button-filter-icon{
        font-size: 20px !important;
    }

}