.share-img{
    width: 40px;
}

.share-link{
    color: black;
    text-decoration: none;
    font-weight: bold;
    text-align: center
}

.share-text{
    margin: 10px
}

.share-div{
    width: 100px;
    height: 100px
}