.navbar {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  font-size: 1.5rem;
  background: none;
}
  
.nav-menu {
  width: 250px;
  height: 100vh;
  background-color: var(--cocoparksGreen);
  border-radius: 0 20px 20px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 10;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 100;
}

.nav-menu-items {
  width: 100%;
  margin-left: 8%
}

.navbar-toggle {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon {
    fill: var(--background);
}

.navbar-button{
    border-style: hidden;
    width: 35px;
    height: 35px;
    background: linear-gradient(270deg, #68C91C 0%, #1FA901 100%);
    border-radius: 50%;  
    color: white;
}

.navbar-button:hover {
  background: linear-gradient(270deg, #1FA901 0%, #68C91C 100%);
  transition: .5s ease-out 0;
  cursor: pointer;
}

.nav-section{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  height: 60px;
}

.nav-text {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.freeze-background{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  position:fixed;
  top:0;
  left: 0;
  transition: 850ms;
  z-index: -1;
  display: none;
}

.freeze-background.active {
  opacity: 0.8;
  z-index: 5;
  display: block;
}

.freeze-background-menu {
    z-index: 50 !important;
}