.label-freed-spot{
    background-color: #1faa00;
    width: 100px;
    height: 44px;
    border: 2px white solid;
    border-radius: 6px;
    color: yellow;
    font-size: 12px;
    text-align: center;
    /* font-weight: bold; */
    vertical-align: middle;
    line-height: 20px;
}

.actual-freed-time{
    margin: 0;
    position: relative;
    font-weight: bold;
    font-size: 16px;
}