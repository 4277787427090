.item-container{
    width: 90%;
    margin: 10px;
    padding: 10px;
    background: white;
    border: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    font-size: 14px;
    box-shadow: 0px 3px 12px rgba(9, 51, 0, 0.15);
}

.all-items-history{
    overflow: scroll;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@media (min-width: 480px) {
    .all-items-history{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 15px
    }
    .item-container{
        max-width: 400px;
        height: 260px;
        margin: 10px;
    }
}