.appSelectPanel {
    background-color: #F0F0F0;
    font-size: 16px;
    font-weight: bold;
    color: #1faa00;
    padding: 5px;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);
}

.appSelectPanelTitle{
    text-align: center;
    border-bottom: dotted lightgray 1px;
}

.appTable{
    margin: 10px;
    width: 100%;
}

.appTableLine{
    display: block;
    width: 80%;
}

.nameApp{
    font-style: bold;
    color: black;
    padding-left: 15px;
    margin: 0px;
}

.closeAppSelectPanel {
    text-align: center;
    color: grey;
    font-weight: bold;
    padding: 5px;
    border-top: dotted lightgray 1px;
}


.appTable img {
    width: 40px;
}