.city-information-panel-container{
    width: 100%;
    background-color: white;
    border-radius: 20px 20px 0 0;
    z-index: 50;
    position: absolute;
    bottom: 0;
}

tbody{
    /* background-color: brown; */
    display: table-row-group !important;
    vertical-align: middle !important;
    border-color: inherit !important;
}

.infoStreetPanelWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    position: absolute;
    z-index: 11;
    height: 300px;
    transition: transform 1.0s ease-out;
}

.infoStreetCityTile{
    font-weight: bold;
    color: white;
    background-color: #1faa00;
    width: 80vw;
    max-width: 750px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    border-radius: 5px;
    padding: 3px 0px;
}

.infoStreetContent{
    height: 200px;
    overflow-y: auto;
}

.infoStreetTable{
    position: relative;
    left: 10%;
    width: 80%;
    height: 100px;
}

tr td {
    padding: 1em;
}

.infoStreetTableLine img{
    width: 130px;
    border-radius: 10px;
}


@media screen and (max-width: 320px) {
    .infoStreetTableLine img {width: 95px;};
}

.infoStreetText{
    font-size: 12px;
    padding-left: 10px;
    text-align: left;
    color: black;
    margin: 0px;
}

.fullwidthInfoStreetLine div{
    margin-top: 10px;
    font-weight: bold;
}


.fullwidthInfoStreetLine img{
    width: 80%;
    max-height: 300px;
    border-radius: 10px;
}

.redirectFPSBtn{
    box-sizing: border-box;
    position: relative;
    margin-top: 10px;
    left: 50%;
    transform: translateX(-50%);
    appearance: none;
    background-color: #1faa00;
    border: 1px solid white;
    border-radius: 0.6em;
    color: white;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1rem;
    line-height: 1;
    /* margin: 20px; */
    padding: 0.5em 1.5em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    /* font-weight: 700; */
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    font-size: 12px;
}

.infoStreetTable{
    position: relative;
    left: 10%;
    width: 80%;
    height: 100px;
}

.infoStreetLink{
    text-decoration: underline;
    font-size: 12px;
    max-width: 80%;
    text-decoration: underline;
    font-weight: normal;
    color: #1faa00;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.infoStreetPanel{
    padding-top: 0px;
    padding-bottom: 5px;
    max-width: 768px;
    color: black;
    width: 100%;
    border-radius: 18px 18px 0 0;
    box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
    font-family: Roboto,sans-serif;
    font-size: 15px!important;
    z-index: 4;
    height: 320px;
    text-align: center;
}

.infoStreetPanelBackground{
    background: white;
    color: #1faa00;
}

.closeInfoStreetPanelWrapper button{
    border: none;
    background: none;
    filter: invert(44%) sepia(20%) saturate(4401%) hue-rotate(76deg) brightness(98%) contrast(101%);
}

.cityPracticalInfoStreetContentNotFound{
    padding: 30px;
}