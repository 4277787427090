.install-cocoparks-container{
    position: fixed;
    top: 2%; 
    left: 2%; 
    background-color: white; 
    width: 96%; 
    z-index: 10;
    border-radius: 20px;
    padding: 5%;
    z-index: 4;
}

.main-install-text{
    font-size: 11px;
}