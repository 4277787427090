.type-title-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%; 
    margin-bottom: 10px; 
    border-radius: 11px;
    padding: 7px 18px;
}

.address-div{
    width: 100%; 
    border-radius: 11px;
    padding: 12px 10px;
    margin-top: 10px; 
}

.address-text{
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    width: 80%;
    margin-left: 10px;
    margin-bottom: 0px;
}

.time-text{
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #333333
}

.time-text-min{
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    text-align: center;
    font-size: 15px;
    line-height: 19px;
    color: #666666;
}

.date-text{
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    color: #333333;
    text-align: center;
}

.date-text-subtitle{
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;  
    color: #666666;
}

.hours-text{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.date-container{
    background: var(--light-grey);
    border-radius: 14px;
    padding: 10px;
}