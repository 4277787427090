.sparked-detail-panel-container{
    width: 100%;
    background-color: var(--cocoparksGreen);
    border-radius: 20px 20px 0 0;
    padding: 0px;
}


.close-sparked-overview-div{
    display:flex;
    justify-content:center;
}


.city-map-button-green{
    width: 60vw;
    background: var(--cocoparksGreen);
    border: none;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    padding: 7px 10px;
    top: -25%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    animation: returnResearchScaleAnimation 2s infinite;
}


.city-map-button-container-green{
    width: 85%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    text-align: center;
}


@keyframes returnResearchScaleAnimation
{
    0%{transform: scale(1);}
    50%{transform: scale(1.05);}
    100%{transform: scale(1);}
}

.pid{
    border-top: 1px solid rgba(#1faa00, 0.25);;
    border-bottom: 1px solid rgba(#1faa00, 0.25);;
    margin: 15px 0 15px 0;
    line-height: 1;
    display: flex;
    justify-content: center;
}

.pid-section{
    display: inline-block;
    font-size: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: overlay;
}

@media screen and (max-width: 320px) {
    .pactions-icons{font-size: 16px !important;}
    .s-pactions-button{font-size: 12px !important;}
    .pid-section{font-size: 13px;}
}

.pname-div{
    text-align: left;
    background-color: #1faa00;
    color: white;
}

.pinfo{
    border-top: 1px solid rgba(#1faa00, 0.25);;
    border-bottom: 1px solid rgba(#1faa00, 0.25);;
    padding: 5px 10px;
    line-height: 1;
    display: flex;
    justify-content: center;
    width: 100%;
}

.s-pinfo-section{
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    border-radius: 15px;
    background: #1faa00;
    padding: 5px;
}

.s-pinfo-section li{
    list-style-type: none;
    padding: 2px 0px 2px 0px;
}

.s-pinfo-section span{
    font-size: 12px;
    vertical-align: middle;
    color: white
}

.s-pinfo-section strong{
    color: #ffd600;
    vertical-align: middle;
}

#keyUpdates{
    background: #1faa00;
    border: solid 1px #ffd600;
    max-width: 140px;
    margin-left: 6px;
}

.capture-button{
    margin: auto;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    cursor: pointer;
    border-radius: 14px;
    width: 60px;
    height: 60px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.35);
}



.pactions{
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    color: white;
    margin: 10px;
}

.s-pactions-button {
    background-color: white; /* #1faa00 */
    border: 1px #DADADA solid;
    border-width: thin;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.24);
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    margin: 2px 6px;
    cursor: pointer;
    border-radius: 10px;
    width: 150px;
    outline: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pactions-icons{
    vertical-align: middle;
    margin-right: 1px;
    font-size: 20px !important;
}

.screenshot-img-saved{
    border: solid red;
    border-width: thin;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    object-fit: cover;
    text-align: center;
    cursor: pointer;
}