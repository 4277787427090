.fav-rec-Addresses-button{
    width: 50%;
    margin: 5px;
    padding: 2% 0;
    background: #EFEEEF;
    border: none;
    color: #BDBDBD;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 14px;
}

.fav-rec-Addresses-button.selected-button{
    color: var(--dark-grey);
}

.recent-Addresses-list{
    margin: 10px;
}

.recent-Addresses-list li{
    width: 100%;
    border-bottom: 1px solid #EFEEEF;
    padding: 1vh 0;
    overflow:hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
}

ul {
    list-style-type: none;
    padding: 0px !important
}

.history-button-icon{
    color: 'black'; 
    height: 22px; 
    width: 22px;
    margin-right: 5px
}

.history-button-icon-item{
    color: var(--cocoparksGreen); 
    height: 22px; 
    width: 22px;
    margin-right: 5px
}

.fav-rec-Addresses-button.selected-button .star{
    color: #fcc107;
}