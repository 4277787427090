.container {
    position: relative;
    width: 36px;
    height: 45px;
    padding: 0px !important;
}

.parkingLogo {
    width: 18px;
    height: 18px; 
    position: absolute; 
    top: 9px;
    left: 9px;
}

.operatorLogo {
    width: 24px;
    height: auto; 
    position: absolute; 
    top: 6px;
    left: 6px;
}
