.alertDiv-title-realtime{
    padding: 10px;
    border-radius: 15px 15px 0 0;
    background-color: var(--cocoparksGreen);
}

.alertDiv-subtitle-realtime{
    text-align:center;
    font-weight:600;
    font-size:1.2em;
    line-height: 1.4
}

.live-nb-parking-spot-legend-row{
    display:flex;
    width:100%;
    align-items:center;
    font-weight:500;
    margin-bottom: 15px;
}

.live-nb-parking-spot-legend-color{
    display:flex;
    width:25%;
    margin-right:5%;
    border-radius:60px;
    height:25px;
}

.live-nb-parking-spot-legend-text{
    display:flex;
    width:70%;
    margin: 0px;
    font-weight: 500;
}

.live-nb-parking-spot-grey{
    background-color:#8b8b8b;
}

.live-nb-parking-spot-green{
    background-color:#57b503;
}

.live-nb-parking-spot-orange{
    background-color:#ff6a00;
}

.live-nb-parking-spot-red{
    background-color:#FA0000;
}

.alertDiv-closeDiv
{
    font-size: 14px;
    color: #fff;
    background: var(--cocoparksGreen);
    width: fit-content;
    margin: 0 auto;
    padding: 5px 30px;
    border-radius: 50px;
    border: none;
    font-weight: bold;
    display: block;
}