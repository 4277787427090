.filter-bar{
    display: flex;
    position: absolute;
    flex-direction: column;
    outline: none;
    background-color: transparent;
    color: transparent;
    top: 65px;
    right: 15px;
    z-index: 2;
}

.settings_logo{
    width: 24px;
    height: 24px;
}

.hover-button {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    width: 40px;
    height: 40px;
    padding: 0px;
    margin: 6px 0 0;
    color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    height: 40px;
    border-radius: 50%;
    /*box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);*/
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.6);
    background-color: white;
}

.button-filter img, .button-filter-icon {
    width: 25px;
}

.button-filter{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px !important;
}

@media screen and (max-width : 320px)
{
    .hover-button{
        width: 30px;
        height: 30px;
    }

    .button-filter img{
        width: 20px;
    }

    .button-filter{
        font-size: 20px !important;
    }

}