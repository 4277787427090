.destination-not-yet-reached-text-div{
    height:15%;
}

.destination-not-yet-reached-buttons-div{
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    width: 100vw;
    margin: 10%;
}

.destination-not-yet-reached-buttons-div button img{
    height:80%;
    max-width: 50px;
    margin: 5px;
}

@media (min-width: 768px) and (max-width: 1024px) { /* For tablet */

    .destination-not-yet-reached-main-buttons{
        width:20%;
    }
}

@media (min-width: 1025px)  {  /* For desktop */

    .destination-not-yet-reached-main-buttons{
        width:10%;
    }
}

.destination-not-yet-reached-main-buttons{
    min-width: 100px;
    background-color:#000;
    border-radius:22px;
    border: 1px solid #FFF;
}


.destination-not-yet-reached-revenir{
    display:flex;
    justify-content:center;
    align-items:flex-start;
    height:12%;
    width: 100%;
}


.intro-user-path-main-buttons{
    border: 1px solid #FFF;
    border-radius: 80px;
    width: 80%;
    height: 70%;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #1FAA00;
    color: #FFF;
    font-weight: 600;
}