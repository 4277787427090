.green-button {
    border-style: hidden;
    width: 70px;
    height: 70px;
    background: linear-gradient(270deg, #68C91C 0%, #1FA901 100%);
    box-shadow: 1px 1px 4px #C4C4C4;
    border-radius: 50%;
    border: solid 2px white;
}

.green-button:hover {
    background: linear-gradient(270deg, #1FA901 0%, #68C91C 100%);
    transition: .5s ease-out 0;
    cursor: pointer;
}

.green-button-text {
    color: #1FA901;
    background: none;
    border: none;
    border-style: hidden;
    text-decoration: underline;
}