.container-login-screen {
    border-radius: 20px 20px 0 0;
    background: #FFF;
    display: flex;
    flex-direction: column;
    min-height: 30%;
    height: auto;
    text-align: center;
    animation: shareAppAnim 2s ease-in;
    position: fixed;
    bottom: 0px;
    width: 100vw;
}

.shareApp_start
{
    animation: shareAppAnimStart .2s ease-in;
}
.shareApp_end
{
    animation: shareAppAnimEnd .2s ease-in;
}

@keyframes shareAppAnimStart
{
    0%{transform: translateY(110%);}
    100%{transform: translateY(0%);}
}

@keyframes shareAppAnimEnd
{
    0%{transform: translateY(0%);}
    100%{transform: translateY(110%);}
}

.user-screen-text{
    font-family: "Roboto";
    line-height: 1.4;
    font-size: 1em;
    font-weight:400;
    color: var(--grey);
    width: 80%;
    margin-top: 1rem;
}

.user-screen-text-bold{
    font-size:1.2em;
    font-weight:600;
    color: var(--dark-grey);
    width: 80%;
}

.user-screen-text-green{
    color: var(--cocoparksGreen);
    font-size: 1em;
    font-weight:300;
    margin: 1rem;
}