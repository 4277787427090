.pinfo_container_placeholder {
    border-radius: 15px;
    background-color: var(--light-grey);
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;
    flex-direction: column;
    padding: 5px 1px 5px 1px;
    height: 100%;
    width: 100%;
}

.pactions-button_placeholder {
    background-color: var(--light-grey);
    border: none;
    color: var(--light-grey);
    padding: 6px;
    text-align: center;
    text-decoration: none;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin: 0px 2px 0px 2px;
    cursor: pointer;
    border-radius: 14px;
    height: 100%;
}

.blue-title-placeholder{
    padding: 0px;
    margin: 0px;
    width: 150px;
    height: 22px;
    background-color: var(--light-grey);
}

.grey-subtitle-placeholder{
    padding: 0px;
    margin: 5px 0px;
    width: 100px;
    height: 18px;
    background-color: var(--light-grey);
}

.city-map-button-placeholder{
    width: 60vw;
    background: var(--light-grey);
    border: none;
    border-radius: 50px;
    color: var(--light-grey);
    position: absolute;
    padding: 7px 10px;
    top: -25%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    animation: returnResearchScaleAnimation 2s infinite;
}

.loading {
    position: relative;
  }

.loading::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .5), transparent);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }