.swiper {
    width: 100vw;
    height: 100vh;
}
  
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: var(--light-green);
    width: 100vw;
    height: 100vh;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.intro-img
{
    position: relative;
    width: 50%;
    margin: 10px auto;
    max-width:30vh;
}

.intro-img img
{
    max-height: 40vh;
}

.introTop
{
    width: 90%;
    /* height: 25%; */
}

.content-intro
{
    font-size: 110%;
    font-weight: normal;
    color: var(--dark-green);
    margin: 10px auto
}

.title-intro{
    font-family: 'Roboto', sans-serif;
    font-weight: 800; 
    font-size: 20px; 
    color: var(--dark-green);
}

@media screen and (max-height : 620px)
{
    .intro-img
    {
        width: 35%;
    }

    .content-intro{
        padding: 0px;
        font-size: 90%;
    }
}

.swiper-pagination-bullet-active {
    background-color: var(--cocoparksGreen);
}

.swiper-button-prev{
    color: var(--cocoparksGreen);
    margin-left: 10px;
}

.swiper-button-next{
    color: var(--cocoparksGreen);
    margin-right: 10px;
}

.swiper-button-disabled{
    display: none;
}