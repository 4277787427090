.zone-with-regulations-container{
    position: absolute;
    top: calc(40px + 2em);
    left: 10px;
}

.zone-with-regulations-button{
    height: 30px;
    background: var(--blue);
    box-shadow: 0px 3px 4px rgba(15, 29, 12, 0.2);
    border-radius: 20px;
    padding: 4px 0px 4px 10px;
}

.zone-with-regulations-legend-container{
    padding: 10px;
    background-color: var(--background);
    box-shadow: 0px 3px 10px rgba(15, 29, 12, 0.2);
    border-radius: 10px;
    margin-top: 9px;
    z-index: 11;
    position: relative;
    max-width: 70%;
}

.title-zone-with-regulations-legend{
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
    color: var(--blue);
    margin-bottom: 0.5em;
    text-decoration: underline;
}

.text-zone-with-regulations-legend{
    font-family: "Roboto";
    font-style: normal;
    font-size: 13px;
    line-height: 13px;
    color: var(--blue);
    margin-bottom: 0.5em;
}

.text-zone-with-regulations{
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.01em; 
    color: var(--background)
}

.icon-zone-with-regulations{
    font-size: 1.8rem;
    color: var(--background);
}

@media (max-width: 340px){
    .text-zone-with-regulations{
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}