.stop-displaying-window{
    font-size:0.75em;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#8C91AB;
    text-decoration:underline;
    position: absolute;
    bottom: 15%
}

.cocoloader{
    margin-bottom: 10px;
}

.cocoloader-div{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    position: absolute;
    top: 15%
}