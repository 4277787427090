.full-green-button {
    border-style: hidden;
    height: 35px;
    background: var(--cocoparksGreen);
    border-radius: 20px;  
    color: white;
    width: 100%;
}

.full-green-button p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 8px;
    color: #FFFFFF;
    margin: 0px;
}

.full-green-button:disabled {
    background: var(--frostini-green);
    cursor: pointer;
}