.installation-legend-iphone{
    position: fixed;
    bottom: 2%; 
    left: 2%; 
    background-color: white; 
    width: 96%; 
    z-index: 10;
    border-radius: 20px;
    padding: 5%;
    z-index: 4;
}

.installation-legend-iphone-background{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    position:fixed;
    top:0;
    left: 0;
    z-index: 100;
}