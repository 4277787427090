@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700;900&display=swap');

.nav-user-menu {
    width: 85vw;
    max-width: 350px;
    height: 100vh;
    background-color: white;
    border-radius: 20px 0 0 20px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    z-index: 10;
}

.nav-user-menu.active {
    right: 0;
    transition: 350ms;
    z-index: 100;
}

.title{
    font-family: 'Roboto', sans-serif;
    font-weight: 800; 
    font-size: 20px; 
    color: #082E00;
}