.direction-info-container{
    border-radius: 10px;
    background-color: white;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;
    flex-direction: column;
    padding: 5px 1px 5px 1px;
    height: 100%;
    width: 100%;  
}

.green-text{
    color: var(--cocoparksGreen);
    font-size: 15px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

table tr td
{
    padding: 3px !important; 
}