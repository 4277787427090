.containerCards{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
}

.card-image{
    padding: 3px 5px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 700;
    color: white;
    background-color: var(--blue);
    text-align: left;
}

.card-image img{
    max-height: 15px;
    vertical-align: middle;
}

/* Card styles */
.card, .card-parking, .card-lvz{
    display: flex;
    color: #111;
    flex-direction: column;
    position: relative;
    height: 100%;
    min-width: 130px;
    max-width: 140px;
    overflow: hidden;
    margin: 12px 8px;
    border-radius: 5px;
    box-shadow: 9px 17px 45px -29px rgba(0, 0, 0, 0.44);
}


.card-title {
    text-align: left;
    padding-left: 4px;
    padding-top: 2px;
    color: rgba(0,69, 149,1);
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 700;
    line-height: 1.4;
    font-size: 12px;
    height: 30px;
    margin-bottom: 0px !important;
    padding-bottom: 0px;
}

.card-title img {
    max-height: 14px;
    vertical-align: middle;
}

.card-description {
    padding: 3px;
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
}

.card-time {
    border-radius: 15px;
    background-color: lightgrey;
    padding: 5px;
    margin: 0px 5px;
    text-align: center;
    left: 0px;
    position: relative;
    font-size: 12px;
    vertical-align: middle;
}

#firstRR {
    display: flex;
    justify-content: space-between;
    color: #111;
    background-color: var(--mist-green);
    flex-direction: column;
    position: relative;
    min-width: 130px;
    max-width: 140px;
    overflow: hidden;
    margin: 12px 8px;
    border-radius: 5px;
}

#firstRR img{
    margin: 0px 3px;
}

#firstRR .card-title{
    color: var(--esmerald-green) !important;
    white-space: normal;
    word-wrap: break-word;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
}

#firstRR .card-image{
    background-color: var(--esmerald-green) !important;
}

.compareColumn-content
{
    margin-left: 3px;
}

.detailsPanelTime{
    font-size: 16px;
    font-weight: bold;
}

.researchAddress{
    max-width: 90%;
    font-size: 16px;
    color: #111;
    font-weight: 500;
    overflow:hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 5px;
}

.grid-container-parking-card {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    align-items: center;
}

.grid-item-parking-card {
    font-size: 10px;
    text-align: center;
    margin-bottom: 10px;
}

.grid-item-parking-card p{
    margin: 0px;
}

.grid-item-parking-card span {
    margin: 0px;
}