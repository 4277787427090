.legend-master{
    position: fixed;
    height:100vh;
    pointer-events:none;
    top: 0px;
    left: 0px;
}

.legend{
    position: relative;
    width:100%;
    font-weight:normal;
    height:100vh;
    pointer-events:none;
}

.legend-all-images{
    background-color:var(--light-grey);
    width: 80%;
    border-top-right-radius:15px;
    border-bottom-right-radius:15px;
}

.legend-title{
    font-size:12px;
    font-weight:500;
    padding-bottom:5px;
}

.legend-subtitle{
    font-size:11.5px;
    text-align: center;
    width: 85%;
}

.legend-button-div{
    align-items:center;
    height: 10%;
    position: fixed;
    top: calc(70px + 3em);
    right: 0;
    width: 5%;
    display:flex;
    justify-content:left;
    left:80%;
    pointer-events:auto;
}

#legend-button{
    background-color:var(--light-grey);
    border-top-right-radius:8px;
    border-bottom-right-radius:8px;
    width:180%;
    height:75px;
    align-items:center;
    justify-content:center;
    display:flex;
}

.table-div{
    background-color: var(--background);
    box-shadow: 5px 5px 5px #C0C0C0;
    border-radius:25px;
    width:85%;
    padding: 5%;
}

.legend-table{
    font-size:0.70em;
    margin:0 auto;
    border-spacing:8px;
}

@media screen and (max-width:330px){
    .legend-table{
        border-spacing:1px;
    }
}

@media screen and (min-width: 331px) and (max-width: 400px){
    .legend-table{
        border-spacing:5px;
    }
}

.legend-td-img{
    width:14%;
    /* overflow-x:hidden; */
    padding-left:10px;
    padding-top:15px
}

.legend-img{
    width:40px;
    height:auto;
    display:block;
    padding-left:15px;
    padding-bottom:5px;

}

.legend-info{
    padding-left:5px;
    padding-right:25px;
    padding-bottom:5px;
    padding-top: 25px;
    width:70%;
    vertical-align:middle;
}