.add-address-container{
    background: white;
    border: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 14px;
    box-shadow: 0px 3px 12px rgba(9, 51, 0, 0.15);
    padding: 10px;
}

.action-button{
    border-style: hidden;
    height: 35px;
    background: var(--light-grey);
    border-radius: 20px;  
}

.action-button-text{
    margin-left: 15px;
    margin-right: 15px;
    color: var(--dark-grey);
    font-weight: bold;
}

.input-destination-address-container{
    width: 100%;
}

.input-destination-address{
    width: 100%;
    border: 2px solid var(--cocoparksGreen);
    border-radius: 20px;
    height: 40px;
    margin-bottom: 10px;
    padding: 10px;
}

.input-destination-address-invalide{
    width: 100%;
    border: 2px solid var(--red);   
    border-radius: 20px;
    height: 40px;
    margin-bottom: 10px;
    padding: 10px;
}

.input-destination-address:focus{
    outline: none !important;
    border: 2px solid var(--light-green);
}


.add-address-text{
    color: black;
    font-weight: 600;
    margin-bottom: 5px;
    margin-left: 10px;
}