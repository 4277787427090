.itinerary-panel-divs{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    height: 100%;
}

@media (min-width: 768px) and (max-width: 1024px) { /* For tablet */
    .itinerary-panel-divs{
        width:65%;
        height:75%;
    }
}

@media (min-width: 1025px)  {  /* For desktop */
    .itinerary-panel-divs{
        width:40%;
        height:75%;
    }
}

.itinerary-panel-elapsed-time{
    display:flex;
    background-color:yellow;
    border:2px solid #FFC000;
}

.itinerary-panel-div{
    width:31%;
    flex-direction:column;
    border-radius:20px;
    background-color:#000;
}

.ip-div-title{
    display:flex;
    justify-content:center;
    align-items:flex-end;
    height:25%;
    font-size:1em;
}

.ip-div-main-content{
    display:flex;
    justify-content:center;
    align-items:center;
    height:50%;
    color:#FFC000;
    font-size:3em;

}

.ip-div-secondary-content{
    display:flex;
    justify-content:center;
    align-items:flex-start;
    height:25%;
}

.itinerary-panel-past-slots{
    display:flex;
    border:2px solid #FFC000;
}

.itinerary-panel-parking{
    display:flex;
    border:2px solid var(--dark-blue);
}


#itinerary-panel{
    justify-content:space-evenly;
    height:100%;
    color:#FFF;
    font-size:13px;
    font-weight:bold;
    line-height: 1.4;
    position:absolute;
    width:100%;
    bottom:1%;
}

@media (max-width: 350px) {
    #itinerary-panel{
        font-size:11px;
    }
    .ip-div-title{
        font-size: 0.9em;
    }
    .ip-div-main-content img{
        height:60%;
    }
}

@media (max-width: 293px) {
    #itinerary-panel{
        font-size:10px;
    }
}

.ip-div-main-content img{
    width:auto;
    height:70%;
}

.close-button{
    position: absolute;
    top: -85px; 
    left: 10px
}

.white-button {
    border-style: hidden;
    width: 70px;
    height: 70px;
    background: var(--background);
    box-shadow: 1px 1px 4px #C4C4C4;
    border-radius: 50%;
    border: solid 2px white;
}

.white-button:hover {
    transition: .5s ease-out 0;
    cursor: pointer;
}

.white-button:disabled {
    opacity: 0.8;
}