.specific-detail-panel-container{
    width: 100%;
    background-color: white;
    border-radius: 20px 20px 0 0;
    z-index: 1;
    padding: 0px 15px 15px 15px
}

.pinfo_container {
    border-radius: 15px;
    border: 1px solid var(--blue);
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;
    flex-direction: column;
    padding: 5px 1px 5px 1px;
    height: 100%;
    width: 100%;
}


.pinfo_parkings {
    display:flex;
    justify-content:center;
    height:100%;
}

.grey-text{
    color: var(--grey);
    font-size: 12px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.blue-text{
    color: var(--blue);
    font-size: 15px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.blue-title{
    color: var(--blue);
    font-size: 15px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
    white-space: nowrap;
    overflow-y: hidden;
    line-height: 2.5;
}

.grey-subtitle{
    color: var(--grey);
    font-size: 12px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow-y: hidden;
}

.featuresDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 35%;
    flex-wrap: wrap;
    padding: 10px;
}

.pactions-button {
    background-color: var(--blue);
    border: none;
    color: white;
    padding: 6px;
    text-align: center;
    text-decoration: none;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin: 0px 2px 0px 2px;
    cursor: pointer;
    border-radius: 14px;
    height: 100%;
    /* width: 150px; */
}

.button-spark{
    background-color: var(--blue) !important;
    padding: 8px;
}

.pactions-parkings {
    display:flex;
    justify-content:center;
    height:27%;
    margin-top: 10px;
    width: 100%;
}

.close-parking-overview-div{
    display:flex;
    justify-content:center;
}

.city-map-button{
    width: 60vw;
    background: var(--blue);
    border: none;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    padding: 7px 10px;
    top: -25%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    animation: returnResearchScaleAnimation 2s infinite;
}

@keyframes returnResearchScaleAnimation
{
    0%{transform: scale(1);}
    50%{transform: scale(1.05);}
    100%{transform: scale(1);}
}

.city-map-button-container{
    width: 85%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    text-align: center;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.text-parking-div{
    width: 80%;
}

@media screen and (max-width: 500px) {
    .text-parking-div {
        width: 65%
    };
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
}

.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 10px;
    padding: 1px;
    text-align: center;
}