.new-research-input-destination {
    outline: none;
    border-style: hidden;
    width: 90vw;
    background-color: white;
    height: 40px;
    border-radius: 20px;
    padding-left: 15px;
    font-size: 0.8em;
}

.new-research-input{
    width: 90%;
    min-width: 180px;
    outline: none;
    background-color: white;
    border-radius: 20px;
    border-style: hidden;
    height: 40px;
}

.error-message{
    color:red;
    display:flex;
    justify-content:center;
    font-size:14px;
    width: 80%;
    margin-top: 15px;
}