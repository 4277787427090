.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    background-color: rgb(0,0,255);
    border: 3px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 30px 2px rgba(0,0,255,0.9);
}

.marker:hover {
  z-index: 1;
}

.marker-disabled {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    background-color: #C4C4C4;
    border: 3px solid #F3F3F3;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.5);
}