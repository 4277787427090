/* COLORS */
:root {
  --background: white;
  --mist-green: #f3fff1;
  --light-green: #d4ffcc;
  --frostini-green: #ddf2d9;
  --cocoparksGreen: #1faa00;
  --cocoparks-grey: #8b8b8b;
  --esmerald-green: #116600;
  --dark-green: #0a3d00;
  --blue: #004285;
  --light-blue: #e0f0ff;
  --grey: #999;
  --light-grey: #f4f4f4;
  --dark-grey: #2c2f33;
  --red: #d11a2a;
  --dark-blue: #004595;
  --filling-orange: #ffbd07;
  --filling-red: #e32c2c;
}

/* ALIGNMENTS */
.flex-center-inline {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-flex-start-inline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-flex-end-inline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-flex-end-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.flex-flex-start-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-flex-start-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-end-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flex-space-around-inline {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-space-between-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-align-top-inline {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/* GLOBAL COMPONENTS */
.green-action-button {
  border-style: hidden;
  height: 35px;
  background: linear-gradient(270deg, #68c91c 0%, #1fa901 100%);
  border-radius: 20px;
  color: white;
}

.green-action-button-text {
  margin-left: 15px;
  margin-right: 15px;
  color: white;
  font-weight: bold;
}

/* TEXT STYLES */
p {
  font-family: "Roboto", sans-serif;
}

.title {
  font-weight: bold;
  padding: 20px;
  font-family: "Roboto", sans-serif;
}

.no-margin {
  margin: 0px;
}

/* MARKER ICON STYLES */
.largeIcon {
  width: 30px;
  height: 30px;
  animation: createBox 0.5s;
}

.smallIcon {
  width: 10px;
  height: 10px;
}

@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* POP UP BACKGROUND */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup::before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.8;
}

.popup-inner {
  position: relative;
  width: 90%;
  max-width: 640px;
}

.pac-container {
  min-width: 60%;
}

/* CONFIRMATION COMPONENT*/

.freeze-background-time-to-park {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.green-button-time-to-park {
  /* used for all screen buttons */
  border: 1px solid #fff;
  border-radius: 80px;
  width: 80%;
  height: 70%;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #1faa00;
  color: #fff;
  font-weight: 600;
}

.green-button-time-to-park:active {
  background-color: #387500;
  box-shadow: 0 3px #666;
  transform: translateY(3px);
}

@media (max-width: 340px) {
  .green-button-time-to-park {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* For tablet */
  .green-button-time-to-park {
    width: 60%;
    height: 60%;
  }
}

@media (min-width: 1025px) {
  /* For desktop */
  .green-button-time-to-park {
    width: 40%;
  }
}

.time-to-park-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  width: 100%;
  font-size: 1.3em;
}

.time-to-park-text {
  width: 80%;
  font-weight: 600;
}

.time-park-button-div {
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ttp-text-min {
  font-size: 13px;
  font-weight: normal;
}

.loading {
  position: relative;
}

.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
