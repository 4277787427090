.original-details-panel-table{
    width: 100%;
    padding: 0 5px 0 5px;
    border-collapse: inherit;
    border-spacing: 5px;
    table-layout:fixed
}

.original-details-panel-table td {
    overflow:visible; 
    white-space:nowrap
}

.green-title{
    margin-right: 10px;
    text-align: center !important;
    color: var(--cocoparksGreen);
    border-bottom: 1px solid var(--cocoparksGreen);
    font-size: 12px;
    padding-bottom: 8px !important;
    width: 30vw;
}

tbody td {
    margin: 10px !important;
    padding: 0 20px 0 20px !important;
}


.liveIndic{
    width: 15px;
    position: absolute;
    top: 0px;
    left: 5px
}

.liveIndic_svg_first
{
    animation-name: liveIndicAnimOpacity;
    animation-duration: .5s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
}

.liveIndic_svg_second
{
    animation-name: liveIndicAnimOpacity;
    animation-duration: .5s;
    animation-delay: .25s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
}


@keyframes liveIndicAnimOpacity
{
    0%{opacity: 1;}
    50%{opacity: .2;}
    100%{opacity: 1;}
}

.leftColumn{
    font-size: 12px;
}

.titleLeftColumn{
    font-weight: bold;
    font-size: 12px;
}

.detailsPanelText{
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    /* overflow: hidden;
    white-space: nowrap; */
}

.detailsPanelTextMin{
    font-size:9px;
    font-weight: normal;
}

@media screen and (max-width : 350px){
    .detailsPanelText{
        font-size:15px;
    }

    .detailsPanelTextMin{
        font-size:8px;
    }

    .titleLeftColumn{
        font-weight: bold;
        font-size: 11px;
    }
}


@media screen and (min-height : 731px)
{
    .detailsPanelText {
        font-size: 16px;
    }
}


@media screen and (min-height : 1024px)
{
  .detailsPanelText {
        font-size: 22px;
  }
}

.infoCirclePosition
{
    width: 14px;
    height: 14px;
    background: blue;
    border: 1px solid #fff;
    border-radius: 50%;
    transition: .3s;
    margin-left: 10px;
    -webkit-box-shadow: 0px 0px 14px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 14px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 14px -1px rgba(0,0,0,0.75);
    z-index: 1;
}

.infoCirclePositionImg
{
    width: 16px !important;
    height: 16px !important;
    background-image: url(../images/viseur.svg) !important;
    background-position:  center !important;
    background-size: cover !important;
    filter: invert(43%) sepia(84%) saturate(2194%) hue-rotate(77deg) brightness(98%) contrast(101%) !important;
    transition: .3s !important;
    margin-left: 10px !important;
    z-index: 1 !important;
}

.infoCirclePositionImgDestination
{
    width: 16px !important;
    height: 16px !important;
    background-image: url(../images/FreedSpot-mkr.png) !important;
    background-position:  center !important;
    background-size: cover !important;
    transition: .3s !important;
    margin-left: 10px !important;
    z-index: 1 !important;
}

.euroPrice{
    border: 1px solid grey;
    border-radius: 15px;
    padding: 2px 10px;
    font-size: 15px;
    vertical-align: middle;
    margin: 0px 15px
}