.gm-style-cc { display:none; }

.detail-panel-container{
    position: fixed;
    bottom: 0px;
    width: 100vw;
    z-index: 3;
}

.sparked-panel-container{
    position: fixed;
    bottom: 0px;
    width: 100vw;
    z-index: 10;
}

.parking-panel-container, .lvz-panel-container{
    position: fixed;
    bottom: 0px;
    width: 100vw;
    z-index: 10;
}

.itinerary-panel-container{
    position: fixed;
    bottom: 0px;
    width: 100vw;
    height: 17%;
    margin-bottom: 2%;
}

.parking-specific-panel-enter, .lvz-specific-panel-enter{
    bottom: 0px;
    width: 100%;
    max-height: 0px;
}

.parking-specific-panel-enter-active, .lvz-specific-panel-enter-active{
    bottom: 0px;
    width: 100%;
    max-height: 380px;
    transition: all 800ms;
}

.parking-specific-panel-exit, .lvz-specific-panel-exit{
    bottom: 0px;
    width: 100%;
    max-height: 380px;
}

.parking-specific-panel-exit-active, .lvz-specific-panel-exit-active{
    bottom: -120px;
    width: 100%;
    max-height: 0px;
    transition: all 800ms;
}

.sparked-panel-enter{
    bottom: 0px;
    width: 100%;
    max-height: 0px;
}

.sparked-panel-enter-active{
    bottom: 0px;
    width: 100%;
    max-height: 380px;
    transition: all 800ms;
}

.sparked-panel-exit{
    bottom: 0px;
    width: 100%;
    max-height: 380px;
}

.sparked-panel-exit-active{
    bottom: -120px;
    width: 100%;
    max-height: 0px;
    transition: all 800ms;
}

.itinerary-panel-enter{
    bottom: 0px;
    width: 100%;
    height: 0px;
}

.itinerary-panel-enter-active{
    bottom: 0px;
    width: 100%;
    height: 17%;
    transition: all 800ms;
}

.itinerary-panel-exit{
    bottom: 0px;
    width: 100%;
    height: 17%;
}

.itinerary-panel-exit-active{
    bottom: -120px;
    width: 100%;
    height: 0px;
    transition: all 800ms;
}