.pill-badge{
    height: 30px;
    border-radius: 15px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pill-badge p{
    margin: 0px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    height: 10px;
    line-height: 1;
}