.user-connected-container{
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

.badge-container{
    text-align: center;
    background: #475468;
    width: 50%;
    padding: 5px;
    border-radius: 50px;
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.badge-text{
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 700;
}

.green-button-user-connected{
    outline: none;
    border: 1px solid var(--cocoparksGreen);
    border-radius: 15px;
    height: 30px;
    color: var(--cocoparksGreen);
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    width: 70%;
}

.green-button-user-connected-selected{
    outline: none;
    border-radius: 15px;
    border: 1px solid var(--cocoparksGreen);
    height: 30px;
    color: var(--background);
    background-color: var(--cocoparksGreen);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    width: 70%;
}

.green-button-text{
    padding: 5px;
    margin: 0px
}

.vehicule-type-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 80%;
    flex-wrap: wrap;
}

.fuel-type-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.fuel-type-container button {
    margin: 5px
}


.green-title-user-connected{
    font-weight: bold;
    font-size: 13px;
    color: var(--cocoparksGreen);
}

.green-button-user-connected img, .green-button-user-connected-selected img{
    height: 20px;
}

.title-user-connected{
    margin-top: 15px;
    margin-bottom: 8px;
}

.signout-button {
    text-align: center;
    display: inline-block;
    background-color: transparent;
    border: none;
    color: #C4C4C4;
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 62px;
    margin-bottom: 20px;
}

.address-container-address-title{
    font-weight: bold;
    color:  var(--cocoparksGreen);
}

.address-container-address-text{
    width: 100%;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    font-weight: normal;
}

.welcome-message{
    text-align: center;
    display: block;
    font-weight: bold;
    font-size: 18px;
}

img.avatar {
    width: 40%;
    border-radius: 50%;
}