.yellow-text-div{
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    width:100%;
    font-size:1.3em;
}

.yellow-text-div .title{
    color:gold;
}

.itinerary-overview{
    justify-content:space-evenly;
    height:17%;
    color:#FFF;
    font-size:13px;
    font-weight:bold;
    line-height: 1.4;
    width:100%;
    margin-bottom:2%;
}

@media (max-width: 350px) {
    .itinerary-overview{
        font-size:11px;
    }
}